import { PaginationRequest } from "./paginationRequest.model";

export class CartaRequest extends PaginationRequest {
  private clienteId: number;
  private localId: number;
  private local: boolean;
  private zonaSubzonas: boolean;
  private userId: number;
  private activa: boolean;
  private tipoCarta: number;
  private nombreCarta: string;
  private nombreEspacio: string;

  constructor() {
    super();
  }

  /**
   *
   */
  public getClienteId(): number {
    return this.clienteId;
  }

  /**
   *
   */
  public setClienteId(clienteId: number): void {
    this.clienteId = clienteId;
  }

  /**
   *
   */
  public getLocalId(): number {
    return this.localId;
  }

  /**
   *
   */
  public setLocalId(localId: number): void {
    this.localId = localId;
  }

  /**
   *
   */
  public getLocal(): boolean {
    return this.local;
  }

  /**
   *
   */
  public setLocal(local: boolean): void {
    this.local = local;
  }

  public getZonaSubzonas(): boolean {
    return this.zonaSubzonas;
  }

  /**
   *
   */
  public setZonaSubzonas(zonaSubzonas: boolean): void {
    this.zonaSubzonas = zonaSubzonas;
  }

  public getUserId(): number {
    return this.userId;
  }

  /**
   *
   */
  public setUserId(userId: number): void {
    this.userId = userId;
  }

  public getActiva(): boolean {
    return this.activa;
  }

  public setActiva(activa: boolean): void {
    this.activa = activa;
  }

  /**
 *
 */
  public getTipoCarta(): number {
    return this.tipoCarta;
  }

  /**
   *
   * @param tipoCarta
   */
  public setTipoCarta(tipoCarta: number): void {
    this.tipoCarta = tipoCarta;
  }

  /**
   *
   */
  public getNombreCarta(): string {
    return this.nombreCarta;
  }

  /**
   *
   * @param nombreCarta
   */
  public setNombreCarta(nombreCarta: string): void {
    this.nombreCarta = nombreCarta;
  }


  /**
   *
   */
  public getNombreEspacio(): string {
    return this.nombreEspacio;
  }

  /**
   *
   * @param nombreEspacio
   */
  public setNombreEspacio(nombreEspacio: string): void {
    this.nombreEspacio = nombreEspacio;
  }
}
